import React, { useState } from 'react';
import styled from 'styled-components';
import { RowTile } from '../../../Reusables/RowTile';
import { Button } from '@rtkwlf/fenrir-react';
import { gantt } from 'dhtmlx-gantt';
import Gantt from './Gantt';
import { caseSelectors } from '../../../Global/caseReducer';
import { riskSelectors } from '../../../Global/riskReducer';
import moment from 'moment';
import { BLUE, GREEN, ORANGE, RED } from '../../../Constants/Styles';
import { useSelector } from 'react-redux';
import Filters from './Filters';
import { OPEN_INFO_PANE } from '../../../InfoPanes/infoPaneActions';
import { CloseCase } from '../../../Modals/Modals/CloseCase';
import CreateCaseModal from '../../../Modals/Modals/CreateCase';
import { useAppDispatch } from '../../../hooks';

const StyledContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;

const getColor = (score: number) => {
  if (score < 4) return GREEN.primary;
  else if (score < 7) return ORANGE.primary;
  return RED.primary;
};

type GanttTaskRow = any;

/**
 * Consume Redux store, build data to feed to the gantt chart;
 * memoization is used to prevent unnecessary chart re-renders.
 * We use a third party non-React solution, and a re-render will reset its local state.
 */
const useGanttData = () => {
  const caseMap = useSelector(caseSelectors.getCaseMap);
  const assignedRiskMap = useSelector(riskSelectors.getAssignedRiskMap);

  return React.useMemo(() => {
    const caseRows: Array<GanttTaskRow> = [],
      riskRows: Array<GanttTaskRow> = [];

    // sort cases by startDate ASC
    const cases = Array.from(caseMap.values()).sort((a, b) => {
      if (a.startDate === b.startDate) return 0;
      return a.startDate < b.startDate ? -1 : 1;
    });

    const risks = Array.from(assignedRiskMap.values());

    cases.forEach((Case) => {
      let caseStartDate = moment(Case.startDate ? Case.startDate : new Date());
      let caseEndDate = moment(Case.startDate).add('1', 'days');

      // find children risks associated to this case
      const caseRisks = risks.filter(
        (risk) => risk.attributes.caseId === Case.id
      );

      // build risk rows
      caseRisks.forEach((risk) => {
        const riskStartDate = moment(
          risk.attributes.assignment?.startDate ?? Case.startDate
        );
        const riskEndDate = risk.attributes.assignment?.dueDate
          ? moment(risk.attributes.assignment.dueDate)
          : moment().add(1, 'days');

        // update start and end for parent case if any child risk spans longer
        if (riskStartDate.isBefore(caseStartDate)) {
          caseStartDate = riskStartDate;
        }
        if (caseEndDate < riskEndDate) {
          caseEndDate = riskEndDate;
        }

        const difference = riskEndDate
          .endOf('date')
          .diff(riskStartDate.startOf('date'), 'd');

        riskRows.push({
          id: risk.id,
          text: risk.issueName,
          riskState: risk.state,
          start_date: riskStartDate.format('YYYY-MM-DD'),
          end_date: riskEndDate.format('YYYY-MM-DD'),
          length: `${difference} ${difference > 1 ? 'Days' : 'Day'}`,
          parent: Case.id,
          color: getColor(risk.issueLevel),
          icon: getColor(risk.issueLevel),
          days: `${riskStartDate.format('MMM D')} - ${riskEndDate.format(
            'MMM D'
          )}`,
        });
      });

      // build case row
      const difference = caseEndDate.diff(caseStartDate, 'days');

      caseRows.push({
        id: Case.id,
        text: Case.title,
        numberOfRisks: `${caseRisks.length || 0} Risks`,
        description: Case.description,
        length: `${difference} ${difference > 1 ? 'Days' : 'Day'}`,
        start_date: caseStartDate.format('YYYY-MM-DD'),
        end_date: caseEndDate.format('YYYY-MM-DD'),
        color: BLUE.primary,
        days: `${caseStartDate.format('MMM D')} - ${caseEndDate.format(
          'MMM D'
        )}`,
      });
    });

    return {
      data: [...caseRows, ...riskRows],
    };
  }, [caseMap, assignedRiskMap]);
};

const CaseRow = () => {
  const isProcessing = useSelector(caseSelectors.getCaseIsProcessing);

  const dispatch = useAppDispatch();
  const onClick = React.useCallback(
    (riskId: string) => {
      // roughly check we received a valid UUID
      if (riskId.length === 36) {
        dispatch({
          type: OPEN_INFO_PANE,
          payload: {
            pane: 'RiskPane',
            width: '400px',
            title: 'Risk Details',
            payload: { riskId },
          },
        });
      }
    },
    [dispatch]
  );

  const [pageZoom, setPageZoom] = useState('week');

  const data = useGanttData();

  const [createCaseModalOpen, setCreateCaseModalOpen] = useState(false);
  const [closeCaseModalOpen, setCloseCaseModalOpen] = useState(false);

  return (
    <div style={{ marginRight: '-20px', marginTop: '5px' }}>
      <RowTile
        title='Plan'
        description={'The current plans associated with risks in your network.'}
      >
        <div className='gantt-container'>
          <Filters
            pageZoom={pageZoom}
            goToToday={() => gantt.showDate(new Date())}
            zoomTo={(level: string) => {
              gantt.ext.zoom.setLevel(level);
              setPageZoom(level);
            }}
          />
          <Gantt onClick={onClick} data={data} isProcessing={isProcessing} />
        </div>
        <StyledContainer>
          <Button onClick={() => setCreateCaseModalOpen(true)}>
            Create Plan
          </Button>
          <Button onClick={() => setCloseCaseModalOpen(true)}>
            Close Plan
          </Button>
        </StyledContainer>
      </RowTile>

      {/* case modals */}

      <CloseCase
        isOpen={closeCaseModalOpen}
        onClose={() => setCloseCaseModalOpen(false)}
      />

      <CreateCaseModal
        isOpen={createCaseModalOpen}
        onClose={() => setCreateCaseModalOpen(false)}
      />
    </div>
  );
};

export default CaseRow;
